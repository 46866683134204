// response.js
import * as commonServices from './common';
import * as toast from "../Wrapper/toast/toast";
import axios from 'axios';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED } from '../Constants/responseCodes';
import { trackAPITime } from '../helpers/analytics';

export function handleResponse(response, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, SUCCESS_CODE);
    if (response.data.code === SUCCESS_CODE || response.data === RESOURCE_CREATED) {
        response.data.isSuccess = true;
    } else if (response.data.code === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.href = '/';
        toast.warn(response.data.message);
        return;
    } else {
        response.data.isSuccess = false;
    }
    return response.data;
}

export function handleError(error, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    
    if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
        return;
    }

    let { response } = error;

    trackAPITime(apiTime, url, "ERROR");

    if (response?.status === UNAUTHORIZED_ERROR_CODE) {
        commonServices.clearLocalStorageData();
        window.location.href = '/';
        toast.warn(response?.data?.message);
        return;
    } else {
        response.data.isSuccess = false;
        return response.data;
    }
}
